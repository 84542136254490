import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Privacy Policy (September 15, 2017)',
  description: 'Uploadcare Privacy Policy (September 15, 2017) uses stored data only for internal processing. We never sell or otherwise give away the data. Please feel free to contact us if you have any questions about Uploadcare’s Privacy Policy.',
  header: 'Privacy Policy (September 15, 2017)',
  canonical: 'https://uploadcare.com/about/privacy-policy/',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Note: this Privacy Policy is outdated, its relevant version came into effect on
May 25, 2018 and can be found `}<a parentName="p" {...{
        "href": "/about/privacy-policy/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Effective Date: September 15, 2017`}</p>
    <p>{`In order to provide our service, we capture and store some information about
you, and the users uploading material to the service:`}</p>
    <ol>
      <li parentName="ol">{`IP Addresses`}</li>
      <li parentName="ol">{`Request Headers`}</li>
      <li parentName="ol">{`Data entered on our website`}</li>
      <li parentName="ol">{`Data submitted to our API`}</li>
    </ol>
    <p>{`We don’t use data from uploaded files for anything, except for:`}</p>
    <ol>
      <li parentName="ol">{`Detecting its MIME-type`}</li>
      <li parentName="ol">{`Generating preview on request`}</li>
      <li parentName="ol">{`Converting or modifying the files on request from Account owner of that file`}</li>
    </ol>
    <p>{`All data stored by us is only used for internal processing, and never sold or
otherwise given away. In addition to that, we use the following third party
services which also store data about you:`}</p>
    <ol>
      <li parentName="ol">{`Amazon Web Services (for data)`}</li>
      <li parentName="ol">{`Google Analytics, Kissmetrics (for statistics)`}</li>
      <li parentName="ol">{`Logdna, Rollbar (for logs)`}</li>
    </ol>
    <p>{`If Account owner will explicitly decline gathering of statistics on “his side”
(in browser or mobile device), we will gather only anonymous stats that we are
using only to improve the service.`}</p>
    <p>{`All gathered statistics will be used only by Uploadcare and none of that
information will be shared with 3rd parties. We can publish some numbers on our
site or blog for marketing purposes.`}</p>
    <p>{`Uploadcare complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S.
Privacy Shield Frameworks as set forth by the U.S. Department of Commerce
regarding the collection, use, and retention of personal information transferred
from the European Union and Switzerland to the United States. Uploadcare has
certified to the Department of Commerce that it adheres to the Privacy Shield
Principles. If there is any conflict between the terms in this privacy policy
and the Privacy Shield Principles, the Privacy Shield Principles shall govern.
To learn more about the Privacy Shield program, and to view our certification,
please visit `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/"
      }}>{`https://www.privacyshield.gov/`}</a>{`. For
more information, see our `}<a parentName="p" {...{
        "href": "/about/privacy-shield-notice/"
      }}>{`Privacy Shield Notice`}</a>{`.`}</p>
    <p>{`Please also feel free to contact us if you have any questions about Uploadcare's
Privacy Policy or practices. You may contact us at
`}<a parentName="p" {...{
        "href": "mailto:hello@uploadcare.com"
      }}>{`hello@uploadcare.com`}</a>{` or at our mailing address below:`}</p>
    <p>{`Uploadcare, LLC`}<br />{` 18401 Collins Ave, Ste. 100-241,`}<br />{` Sunny Isles Beach, FL
33160`}<br />{` USA`}</p>
    <p><a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/2.5/"
      }}><img parentName="a" {...{
          "src": "https://licensebuttons.net/l/by-sa/2.5/88x31.png",
          "alt": "Creative Commons Attribution-ShareAlike 2.5 Generic License"
        }}></img></a>{`
This text is licensed under `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/2.5/"
      }}>{`Creative Commons Attribution-ShareAlike 2.5 Generic
License`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      